@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?jh91xa');
  src:  url('fonts/icomoon.eot?jh91xa#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?jh91xa') format('truetype'),
    url('fonts/icomoon.woff?jh91xa') format('woff'),
    url('fonts/icomoon.svg?jh91xa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-analytics:before {
  content: "\e900";
}
.icon-cup:before {
  content: "\e901";
}
.icon-employee-2:before {
  content: "\e902";
}
.icon-money:before {
  content: "\e903";
}
.icon-smartphone-1:before {
  content: "\e904";
}
.icon-united-kingdom:before {
  content: "\e905";
}
.icon-balloons:before {
  content: "\e906";
}
.icon-data-protection:before {
  content: "\e907";
}
.icon-delivery-complete:before {
  content: "\e908";
}
.icon-dialog:before {
  content: "\e909";
}
.icon-smartphone:before {
  content: "\e90a";
}
.icon-travel:before {
  content: "\e90b";
}
.icon-employee-1:before {
  content: "\e90c";
}
.icon-gear:before {
  content: "\e90d";
}
.icon-happiness:before {
  content: "\e90e";
}
.icon-medical-history:before {
  content: "\e90f";
}
.icon-mortarboard2:before {
  content: "\e910";
}
.icon-software:before {
  content: "\e911";
}
.icon-desk:before {
  content: "\e912";
}
.icon-employee:before {
  content: "\e913";
}
.icon-software-1:before {
  content: "\e914";
}
.icon-web-development:before {
  content: "\e915";
}
.icon-workplace:before {
  content: "\e916";
}
.icon-salary:before {
  content: "\e917";
}
.icon-settings:before {
  content: "\e918";
}
.icon-team:before {
  content: "\e919";
}
.icon-first-aid-kit:before {
  content: "\e91a";
}
.icon-headphones:before {
  content: "\e91b";
}
.icon-interview:before {
  content: "\e91c";
}
.icon-coding:before {
  content: "\e91d";
}
.icon-seo:before {
  content: "\e91e";
}
.icon-prototype:before {
  content: "\e91f";
}
.icon-accounting:before {
  content: "\e920";
}
.icon-credit-card:before {
  content: "\e921";
}
.icon-factory:before {
  content: "\e922";
}
.icon-industrial-robot:before {
  content: "\e923";
}
.icon-medical:before {
  content: "\e924";
}
.icon-mortarboard:before {
  content: "\e925";
}
.icon-mortgage:before {
  content: "\e926";
}
.icon-online-shopping:before {
  content: "\e927";
}
.icon-rocket:before {
  content: "\e928";
}
.icon-skyline:before {
  content: "\e929";
}
.icon-technology:before {
  content: "\e92a";
}
.icon-rss:before {
  content: "\e92b";
}
.icon-upwork:before {
  content: "\e92c";
}
.icon-freelance:before {
  content: "\e92d";
}
.icon-linkdn:before {
  content: "\e92e";
}
.icon-googleplus:before {
  content: "\e92f";
}
.icon-facebook:before {
  content: "\e930";
}
.icon-twitter:before {
  content: "\e931";
}
.icon-quotes-left:before {
  content: "\e977";
}
.icon-quotes-right:before {
  content: "\e978";
}
